//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// https://nuxtjs.org/guide/views/#error-page checkout this paragraph

export default {
  props: ["error"],
  layout: "default",
  head() {
    const title = `Error ${this.error.statusCode}`
    return {
      title,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
      ]
    };
  },
  mounted() {
    document.getElementById("body").className = "page-homepage"
    document.querySelector("nav").className = "nav"
  },
  destroyed() {
    document.getElementById("body").className = ""
    document.querySelector("nav").className = "nav"
  },
  computed: {
    signInUrl() {
      if (this.error.statusCode === 401) {
        return `/auth/login?redirect=${encodeURIComponent(this.$route.fullPath)}`
      }

      return '/'
    }
  }
};
