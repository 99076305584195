//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
  mapGetters,
} from 'vuex'
import {
  APPEND_NOTIFICATION,
  SET_NOTIFICATIONS_UNREAD_COUNT,
} from '@/store/mutation-types'
import LazyModalSignUpFranchisee from "@/components/modals/ModalSignUpFranchisee"
import LazyMessageNarrowYouSearchFranchisee from "@/components/banners/MessageNarrowYouSearchFranchisee"
import LazyNavbar from "@/components/Navbar"
import LazyModalWorkBook from "@/components/modals/Account/ModalWorkBook"
import LazyPendingList from "@/components/lists/PendingList"
import storeMixin from "@/components/mixins/storeMixin"

export default {
  components: {
    LazyPendingList,
    LazyModalWorkBook,
    LazyNavbar,
    LazyMessageNarrowYouSearchFranchisee,
    LazyModalSignUpFranchisee,
  },
  mixins: [
    storeMixin
  ],
  data() {
    return {
      APPEND_NOTIFICATION,
      SET_NOTIFICATIONS_UNREAD_COUNT,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuth: 'auth/check',
      notificationsUnreadCount: 'notifications/notificationsUnreadCount',
    }),
  },
  mounted() {
    const fromMobileApp = this.$route.query?.signin

    if (fromMobileApp === 'franchisee') {

      if (this.isAuth && this.user.isFranchisee) {
        this.$router.push('/account/comparisons')
        return
      }

      this.afterSignInFranchiseRedirectToComparisons()
      this.$store.dispatch('login/openModal')
    }

    if (this.user) {
      this.$echo
        .private(`App.User.${this.user.id}`)
        .listen('.message.notification', ({notification}) => {
          this.$store.commit(`notifications/${this.APPEND_NOTIFICATION}`, notification)
          this.$store.commit(`notifications/${this.SET_NOTIFICATIONS_UNREAD_COUNT}`, Number(this.notificationsUnreadCount + 1))
        })
    }
    if (
      !this.isAuth &&
      typeof this.$route.query?.set_pending_list !== "undefined" &&
      typeof this.$route.query?.inviting !== "undefined"
    )
      this.showFranchiseePendingListRegistration(this.$route.query.inviting)
  },
  methods: {
    ...mapActions({
      afterSignInFranchiseRedirectToComparisons: 'auth/afterSignInFranchiseRedirectToComparisons'
    })
  }
}
